import defaultTranslations from '../../../../../translation.en.json';
import i18next from 'i18next';
import Locize, { type LocizeBackendOptions } from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import { appConfig } from 'src/lib/config';

declare module 'i18next' {
    export interface CustomTypeOptions {
        defaultNS: 'locize';
        // fallbackNS: 'json';
        returnNull: false;
        keySeparator: '::';
        resources: {
            /**
             * Typing the "locize" namespace from the translation defaults file to ensure we always have a default
             */
            // eslint-disable-next-line @typescript-eslint/consistent-type-imports
            locize: typeof import('../../../../../translation.en.json');
            // eslint-disable-next-line @typescript-eslint/consistent-type-imports
            json: typeof import('../../../../../translation.en.json');
        };
        strictKeyChecks: true;
    }
}

i18next
    .use(Locize)
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init<LocizeBackendOptions>({
        backend: {
            projectId: 'e51bcb59-5061-43ae-ac1e-5b39de0b3936',
            referenceLng: 'en',
            version: appConfig.i18nVersion,
        },
        resources: {
            en: {
                json: defaultTranslations,
            },
        },
        defaultNS: 'locize',
        fallbackNS: 'json',
        fallbackLng: 'en',
        keySeparator: '::',
        nsSeparator: false,
    });
