/*
 * Some modules are loaded in the container so that they are available in module scope to the rest of the application.
 *
 * We guarantee that this setup file is imported after polyfills, but before the prelude and any other module.
 */
import { TypedContainer } from '@thinkalpha/common/ioc/TypedContainer.js';
import { i18nModule } from 'src/bootstrap/iocModules/i18nModule';
import { logsModule } from 'src/bootstrap/iocModules/logsModule';
import { webDatadogModule } from 'src/bootstrap/iocModules/webDatadogModule';
import { ConfigServiceImpl } from 'src/services/ConfigService/impl';
import type { StaticBindings } from 'src/types/bindings';

const container = new TypedContainer<StaticBindings>();
container.bind('ConfigService').to(ConfigServiceImpl).inSingletonScope();
container.load(i18nModule);
container.load(webDatadogModule);
container.load(logsModule);

// Ensure datadog RUM initializes
container.getOptional('DatadogService');

window.container = container;
